import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';


function Footer() {
  return (
    <div className='footer-container'>
     
      <section class='social-media'>
        <div class='social-media-wrap'>
        <Link to='/aboutme' className='social-logo'>
              ABOUT
        </Link>
          
          <big class='website-rights'>WAFAA LAHLAH © 2024</big>
          <div class='social-icons'>
          <a
              class='social-icon-link mail'
              href="mailto:wafaa.lahlah44@gmail.com" 
              aria-label='LinkedIn'
            >
              <i class='fa fa-envelope' />
            </a>
            <a
              class='social-icon-link instagram'
              href='https://www.instagram.com/wafaalahlahfashion/?hl=fr'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </a>
            <a
              class='social-icon-link linkedin'
              href="https://fr.linkedin.com/in/wafaa-lahlah-2724246a" 
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;

import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Rdm from '../Rdm';

function RdmP() {
  return (
    <>
      <Rdm />
      <Footer />
    </>
  );
}
export default RdmP

import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import AboutMe from './components/pages/AboutMe';
import Rdm from './components/pages/RdmP';
import Couture from './components/pages/CoutureP';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes> {/* Use Routes instead of Switch */}
          <Route path='/' element={<Home />} /> {/* Use element instead of component */}
          <Route path='/awards' element={<Services />} />
          <Route path='/press' element={<Products />} />
          <Route path='/aboutme' element={<AboutMe />} />
          <Route path='/rdm' element={<Rdm />} />
          <Route path='/couture' element={<Couture />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

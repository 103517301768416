import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import About from '../About';

function AboutMe() {
  return (
    <>
      <About/>

    </>
  );
}
export default AboutMe

import React from 'react';
import ImageGallery from 'react-image-gallery';


function Couture() {
  const images = [
    {
      original: './images/couture/couture1.jpg ',
      thumbnail: './images/couture/couture1.jpg',
    },
    {
      original: './images/couture/couture2.jpg',
      thumbnail: './images/couture/couture2.jpg',
    },
    {
      original: './images/couture/couture3.jpg',
      thumbnail: './images/couture/couture3.jpg',
    },
    {
      original: './images/couture/couture4.jpg',
      thumbnail: './images/couture/couture4.jpg',
    },
    {
      original: './images/couture/couture5.jpg',
      thumbnail: './images/couture/couture5.jpg',
    },
    {
      original: './images/couture/couture6.jpg',
      thumbnail: './images/couture/couture6.jpg',
    },
    {
      original: './images/couture/couture7.jpg',
      thumbnail: './images/couture/couture7.jpg',
    },
    {
      original: './images/couture/couture8.jpg',
      thumbnail: './images/couture/couture8.jpg',
    },
    {
      original: './images/couture/couture9.jpg',
      thumbnail: './images/couture/couture9.jpg',
    },
    
  ];

  return <ImageGallery items={images} />;
}

export default Couture;

// src/components/Awards.js

import React, { useRef, useEffect } from "react";
import data from "../data";
import "./Awards.css";

function Awards() {
  const timelineRef = useRef(null);

  useEffect(() => {
    const timeline = timelineRef.current;
    let isDown = false;
    let startX;
    let scrollLeft;

    const start = (e) => {
      isDown = true;
      timeline.classList.add('active');
      startX = e.pageX || e.touches[0].pageX - timeline.offsetLeft;
      scrollLeft = timeline.scrollLeft;
    };

    const end = () => {
      isDown = false;
      timeline.classList.remove('active');
    };

    const move = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX || e.touches[0].pageX - timeline.offsetLeft;
      const walk = (x - startX) * 2;
      timeline.scrollLeft = scrollLeft - walk;
    };

    timeline.addEventListener('mousedown', start);
    timeline.addEventListener('touchstart', start);

    timeline.addEventListener('mouseleave', end);
    timeline.addEventListener('mouseup', end);
    timeline.addEventListener('touchend', end);

    timeline.addEventListener('mousemove', move);
    timeline.addEventListener('touchmove', move);

    return () => {
      timeline.removeEventListener('mousedown', start);
      timeline.removeEventListener('touchstart', start);
      timeline.removeEventListener('mouseleave', end);
      timeline.removeEventListener('mouseup', end);
      timeline.removeEventListener('touchend', end);
      timeline.removeEventListener('mousemove', move);
      timeline.removeEventListener('touchmove', move);
    };
  }, []);

  return (
    <div className="awards-container">
      <h1>Awards & Achievements</h1>
      <div className="timeline" ref={timelineRef}>
        {data.map((award, index) => (
          <div className="card" key={index}>
            <div className="timeline-dot"></div>
            <img src={award.imageUrl} alt={award.title} className="card-image" />
            <div className="card-content">
              <h2 className="card-title">{award.title}</h2>
              <h3 className="card-subtitle">{award.subtitle}</h3>
              <p className="card-description">{award.description}</p>
              <p className="card-date">{award.date}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Awards;
import React, {useState} from 'react';
import './Cards.css';
import CardItem from './CardItem';
import Modal from 'react-modal'
import { ModalProvider, ModalContext, ModalRoot } from 'react-multi-modal';

import ImageGallery from 'react-image-gallery';


const Brune = [
  {
    original: './images/press/brunePG40.jpg ',
    thumbnail: './images/press/brunePG40.jpg',
  }
];

const Faust = [
  {
    original: './images/press/fautMagazineM.jpg ',
    thumbnail: './images/press/fautMagazineM.jpg',
  },
  {
    original: './images/press/faust5455.jpg ',
    thumbnail: './images/press/faust5455.jpg',
  }
  
];

const Amilcar4 = [
  {
    original: './images/press/milcar4pg58.jpg ',
    thumbnail: './images/press/milcar4pg58.jpg',
  },
  {
    original: './images/press/milcar4pg59.jpg',
    thumbnail: './images/press/milcar4pg59.jpg',
  }

];

const Amilcar3 = [
  {
    original: './images/press/milcar18pg87.jpg ',
    thumbnail: './images/press/milcar18pg87.jpg',
  }

];

const Fimapg62 = [
  {
    original: './images/press/FimaPg62.jpg ',
    thumbnail: './images/press/FimaPg62.jpg',
  }

];

const eyeshot = [
  {
    original: './images/press/eyeShot.jpg ',
    thumbnail: './images/press/eyeShot.jpg',
  }

];

const blackNWhite = [
  {
    original: './images/press/blackNWhiteC.jpg ',
    thumbnail: './images/press/blackNWhiteC.jpg',
  }

];

const md = [
  {
    original: './images/press/md1.png ',
    thumbnail: './images/press/md1.png',
  },

  {
    original: './images/press/md2.png ',
    thumbnail: './images/press/md2.png',
  },

  {
    original: './images/press/md3.png ',
    thumbnail: './images/press/md3.png',
  },
  {
    original: './images/press/md4.png ',
    thumbnail: './images/press/md4.png',
  },
  {
    original: './images/press/md5.png ',
    thumbnail: './images/press/md5.png',
  },
  {
    original: './images/press/md6.png ',
    thumbnail: './images/press/md6.png',
  },
  {
    original: './images/press/md7.png ',
    thumbnail: './images/press/md7.png',
  }

];

const prestige = [
  {
    original: './images/press/pi1.png ',
    thumbnail: './images/press/pi1.png',
  },

  {
    original: './images/press/pi2.png ',
    thumbnail: './images/press/pi2.png',
  },

  {
    original: './images/press/pi3.png ',
    thumbnail: './images/press/pi3.png',
  },
  {
    original: './images/press/pi4.png ',
    thumbnail: './images/press/pi4.png',
  },
  {
    original: './images/press/pi5.png ',
    thumbnail: './images/press/pi5.png',
  },
  {
    original: './images/press/pi6.png ',
    thumbnail: './images/press/pi6.png',
  },
  {
    original: './images/press/pi7.png ',
    thumbnail: './images/press/pi7.png',
  },
  {
    original: './images/press/pi8.png ',
    thumbnail: './images/press/pi8.png',
  }

];


Modal.setAppElement('#root')
function Cards() {
  const[modalOneIsOpen, setmodalOneIsOpen] = useState(false);
  const[modalTwoIsOpen, setmodalTwoIsOpen] = useState(false);
  const[modalThreeIsOpen, setmodalThreeIsOpen] = useState(false);
  const[modalFourIsOpen, setmodalFourIsOpen] = useState(false);
  const[modalFiveIsOpen, setmodalFiveIsOpen] = useState(false);
  const[modalSixIsOpen, setmodalSixIsOpen] = useState(false);
  const[modalSevenIsOpen, setmodalSevenIsOpen] = useState(false);
  const[modalEightIsOpen, setmodalEightIsOpen] = useState(false);
  const[modalNineIsOpen, setmodalNineIsOpen] = useState(false);
  const[modalTenIsOpen, setmodalTenIsOpen] = useState(false);
  const[modalElevenIsOpen, setmodalElevenIsOpen] = useState(false);


  return (
    <div className='cards'>
      <Modal 
      
        isOpen={modalOneIsOpen} 
        
        onRequestClose={() => setmodalOneIsOpen(false)}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'black',
            overflow:'unscrollable'
          },
          content: {
            position: 'absolute',
            top: '40px',
            left: '40px',
            right: '20px',
            bottom: '40px',
            border: '0px solid #ccc',
            background: 'black',
            overflow: 'auto',
            borderRadius: '0px',
            outline: 'none',
            padding: '20px'
          }
        }}>
        
        <div>

      
       
        <div>

        <button class="button"
                type="button"
                
                onClick={() => setmodalOneIsOpen(false) }
              >     
                <span class= "button-span" aria-hidden="true">&times;</span>
              </button>
        </div>
        
        

        <ImageGallery class='imageGallery' items={Faust} />
        
        </div>
      </Modal>

      
      <Modal 
      
      isOpen={modalTwoIsOpen} 
      
      onRequestClose={() => setmodalTwoIsOpen(false)}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'black',
          overflow:'unscrollable'
        },
        content: {
          position: 'absolute',
          top: '40px',
          left: '40px',
          right: '20px',
          bottom: '40px',
          border: '0px solid #ccc',
          background: 'black',
          overflow: 'auto',
          borderRadius: '0px',
          outline: 'none',
          padding: '20px'
        }
      }}>
      
      <div>

    
     
      <div>

      <button class="button"
              type="button"
              
              onClick={() => setmodalTwoIsOpen(false) }
            >     
              <span class= "button-span" aria-hidden="true">&times;</span>
            </button>
      </div>
      
      

      <ImageGallery class='imageGallery' items={Amilcar4} />
      
      </div>
    </Modal>


    <Modal 
      
      isOpen={modalThreeIsOpen} 
      
      onRequestClose={() => setmodalThreeIsOpen(false)}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'black',
          overflow:'unscrollable'
        },
        content: {
          position: 'absolute',
          top: '40px',
          left: '40px',
          right: '20px',
          bottom: '40px',
          border: '0px solid #ccc',
          background: 'black',
          overflow: 'auto',
          borderRadius: '0px',
          outline: 'none',
          padding: '20px'
        }
      }}>
      
      <div>

    
     
      <div>

      <button class="button"
              type="button"
              
              onClick={() => setmodalThreeIsOpen(false) }
            >     
              <span class= "button-span" aria-hidden="true">&times;</span>
            </button>
      </div>
      
      

      <ImageGallery class='imageGallery' items={Amilcar3} />
      
      </div>
    </Modal>

    <Modal 
      
      isOpen={modalFourIsOpen} 
      
      onRequestClose={() => setmodalFourIsOpen(false)}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'black',
          overflow:'unscrollable'
        },
        content: {
          position: 'absolute',
          top: '40px',
          left: '40px',
          right: '20px',
          bottom: '40px',
          border: '0px solid #ccc',
          background: 'black',
          overflow: 'auto',
          borderRadius: '0px',
          outline: 'none',
          padding: '20px'
        }
      }}>
      
      <div>

    
     
      <div>

      <button class="button"
              type="button"
              
              onClick={() => setmodalFourIsOpen(false) }
            >     
              <span class= "button-span" aria-hidden="true">&times;</span>
            </button>
      </div>
      
      

      <ImageGallery class='imageGallery' items={Faust} />
      
      </div>
    </Modal>

    <Modal 
      
      isOpen={modalFiveIsOpen} 
      
      onRequestClose={() => setmodalFiveIsOpen(false)}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'black',
          overflow:'unscrollable'
        },
        content: {
          position: 'absolute',
          top: '40px',
          left: '40px',
          right: '20px',
          bottom: '40px',
          border: '0px solid #ccc',
          background: 'black',
          overflow: 'auto',
          borderRadius: '0px',
          outline: 'none',
          padding: '20px'
        }
      }}>
      
      <div>

    
     
      <div>

      <button class="button"
              type="button"
              
              onClick={() => setmodalFiveIsOpen(false) }
            >     
              <span class= "button-span" aria-hidden="true">&times;</span>
            </button>
      </div>
      
      

      <ImageGallery class='imageGallery' items={prestige} />
      
      </div>
    </Modal>  

    <Modal 
      
      isOpen={modalSixIsOpen} 
      
      onRequestClose={() => setmodalSixIsOpen(false)}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'black',
          overflow:'unscrollable'
        },
        content: {
          position: 'absolute',
          top: '40px',
          left: '40px',
          right: '20px',
          bottom: '40px',
          border: '0px solid #ccc',
          background: 'black',
          overflow: 'auto',
          borderRadius: '0px',
          outline: 'none',
          padding: '20px'
        }
      }}>
      
      <div>

    
     
      <div>

      <button class="button"
              type="button"
              
              onClick={() => setmodalSixIsOpen(false) }
            >     
              <span class= "button-span" aria-hidden="true">&times;</span>
            </button>
      </div>
      
      

      <ImageGallery class='imageGallery' items={md} />
      
      </div>
    </Modal>

    <Modal 
      
      isOpen={modalSevenIsOpen} 
      
      onRequestClose={() => setmodalSevenIsOpen(false)}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'black',
          overflow:'unscrollable'
        },
        content: {
          position: 'absolute',
          top: '40px',
          left: '40px',
          right: '20px',
          bottom: '40px',
          border: '0px solid #ccc',
          background: 'black',
          overflow: 'auto',
          borderRadius: '0px',
          outline: 'none',
          padding: '20px'
        }
      }}>
      
      <div>

    
     
      <div>

      <button class="button"
              type="button"
              
              onClick={() => setmodalSevenIsOpen(false) }
            >     
              <span class= "button-span" aria-hidden="true">&times;</span>
            </button>
      </div>
      
      

      <ImageGallery class='imageGallery' items={Brune} />
      
      </div>
    </Modal>

    <Modal 
      
      isOpen={modalEightIsOpen} 
      
      onRequestClose={() => setmodalEightIsOpen(false)}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'black',
          overflow:'unscrollable'
        },
        content: {
          position: 'absolute',
          top: '40px',
          left: '40px',
          right: '20px',
          bottom: '40px',
          border: '0px solid #ccc',
          background: 'black',
          overflow: 'auto',
          borderRadius: '0px',
          outline: 'none',
          padding: '20px'
        }
      }}>
      
      <div>

    
     
      <div>

      <button class="button"
              type="button"
              
              onClick={() => setmodalEightIsOpen(false) }
            >     
              <span class= "button-span" aria-hidden="true">&times;</span>
            </button>
      </div>
      
      

      <ImageGallery class='imageGallery' items={blackNWhite} />
      
      </div>
    </Modal>

    <Modal 
      
      isOpen={modalNineIsOpen} 
      
      onRequestClose={() => setmodalNineIsOpen(false)}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'black',
          overflow:'unscrollable'
        },
        content: {
          position: 'absolute',
          top: '40px',
          left: '40px',
          right: '20px',
          bottom: '40px',
          border: '0px solid #ccc',
          background: 'black',
          overflow: 'auto',
          borderRadius: '0px',
          outline: 'none',
          padding: '20px'
        }
      }}>
      
      <div>

    
     
      <div>

      <button class="button"
              type="button"
              
              onClick={() => setmodalNineIsOpen(false) }
            >     
              <span class= "button-span" aria-hidden="true">&times;</span>
            </button>
      </div>
      
      

      <ImageGallery class='imageGallery' items={Brune} />
      
      </div>
    </Modal>

    <Modal 
      
      isOpen={modalTenIsOpen} 
      
      onRequestClose={() => setmodalTenIsOpen(false)}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'black',
          overflow:'unscrollable'
        },
        content: {
          position: 'absolute',
          top: '40px',
          left: '40px',
          right: '20px',
          bottom: '40px',
          border: '0px solid #ccc',
          background: 'black',
          overflow: 'auto',
          borderRadius: '0px',
          outline: 'none',
          padding: '20px'
        }
      }}>
      
      <div>

    
     
      <div>

      <button class="button"
              type="button"
              
              onClick={() => setmodalTenIsOpen(false) }
            >     
              <span class= "button-span" aria-hidden="true">&times;</span>
            </button>
      </div>
      
      

      <ImageGallery class='imageGallery' items={Fimapg62} />
      
      </div>
    </Modal>

    <Modal 
      
      isOpen={modalElevenIsOpen} 
      
      onRequestClose={() => setmodalElevenIsOpen(false)}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'black',
          overflow:'unscrollable'
        },
        content: {
          position: 'absolute',
          top: '40px',
          left: '40px',
          right: '20px',
          bottom: '40px',
          border: '0px solid #ccc',
          background: 'black',
          overflow: 'auto',
          borderRadius: '0px',
          outline: 'none',
          padding: '20px'
        }
      }}>
      
      <div>

    
     
      <div>

      <button class="button"
              type="button"
              
              onClick={() => setmodalElevenIsOpen(false) }
            >     
              <span class= "button-span" aria-hidden="true">&times;</span>
            </button>
      </div>
      
      

      <ImageGallery class='imageGallery' items={eyeshot} />
      
      </div>
    </Modal>


  

      <div className='cards__container'>
        <div className='cards__wrapper'>

        <ul className='cards__items'>

        <div className='cards__item2'>

        <CardItem 
            src='./images/press/newsight.jpg'
            text='Pg 40'
            label='2019'
          />

        </div>

      
         <div onClick={() => setmodalTwoIsOpen(true)} className='cards__item2'>
         <CardItem
              src='./images/press/milcarC2.jpg'
              text='Pg 58-59'
              label='2019'
            />
        

         </div>

          
          </ul>

          <ul className='cards__items'>

          <div onClick={() => setmodalThreeIsOpen(true)} className='cards__item2'>
            <CardItem
              src='./images/press/milcarC.jpg'
              text='Pg 87'
              label='2018'
            />

          </div>
          
          
          <div onClick={() => setmodalFourIsOpen(true)} className='cards__item2'>

            <CardItem
              src='./images/press/faustMagazineC.png'
              text='Pg 52-55'
              label='2018'
            />
            </div>
          </ul>
          

          <ul className='cards__items'>

          <div onClick={() => setmodalFiveIsOpen(true)} className='cards__item2'>
          <CardItem
              src='./images/press/PrestigeInternationalMagazineC.jpg'
              text='Pg 24-39'
              label='2018'
              /* path='https://issuu.com/prestigeinternational/docs/pim_spring_2018_last' */
            />

          </div>

          <div onClick={() => setmodalSixIsOpen(true)} className='cards__item2'>

           <CardItem
              src='./images/press/ModaDynastieMagazineJuly-August2018.jpg'
              text='Pg 1-15'
              label='2018'
              /* path='https://issuu.com/modadynastie/docs/moda_dynastie_magazine_may-_june_20_d554d04ed20f04' */
            />
           </div>
           
          </ul>
          <ul className='cards__items'>
         
          <div onClick={() => setmodalSevenIsOpen(true)} className='cards__item2'>

            <CardItem
              src='./images/press/brune.png'
              text='Pg 40'
              label='2017'
            />
          </div>

          <div className='cards__item2'>

           <CardItem
             src='./images/press/TiaraMagazine.jpg'
              text='Cover'
              label='2017'
            />
           </div>
           </ul>
          
          
          <ul className='cards__items'>

          <div className='cards__item2'>

          <CardItem
              src='./images/press/DeidoBoyMag.jpg'
              text='Back cover'
              label='2017'
            />
          </div>

          <div onClick={() => setmodalEightIsOpen(true)} className='cards__item2'>

          <CardItem
              src='./images/press/blackNWhite.jpg'
              text='Double page'
              label='2014'/> 
          
          </div>
            
          </ul>

          <ul className='cards__items'>


          <div onClick={() => setmodalTenIsOpen(true)} className='cards__item2'>

          <CardItem
              src='./images/press/fima.png'
              text='Pg 62'
              label='2013'/>

          </div>

          <div onClick={() => setmodalElevenIsOpen(true)} className='cards__item2'>

              <CardItem
              src='./images/press/eyeShot.png'
              text='Pg 6-7'
              label='2012'

            
            />

          </div>
          </ul>

    

            <ul className='cards__items'>

            <div className='cards__item2'>

            <CardItem
              src='./images/press/elwatan31mai2007.png'
              text='El watan'
              label='2007'
            />

            </div>

            </ul>

            <ul className='cards__items'>

            <div  className='cards__item2'>

            <CardItem
              src='./images/press/hc.png'
              text="Soir d'Algerie"
              label='2007'
            />

            </div>

           </ul>

           <ul className='cards__items'>

           <div  className='cards__item2'>

            <CardItem
              src='./images/press/brevesDeStandJuin.png'
              text="L'Est Républicain"
              label='2005'
            />

            </div>

            </ul>
            <ul className='cards__items'>


            <div className='cards__item2'>
           
            <CardItem
              src='./images/press/brevesDeStand2.png'
              text="L'Est Républicain"
              label='2005'
            />

            </div>
            </ul>

           
        </div>
      </div>
    </div>
  );
}

export default Cards;

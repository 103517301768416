// src/data.js

const data = [
  {
    subtitle: "International Fashion Designer",
    imageUrl: "../images/awards/awardChicago.jpg", // Replace with your actual image URL
    date: "Chicago 2017",
  },
  {
    subtitle: "Oscar for Best Fashion Designer", 
    imageUrl: "../images/awards/oscar2017.jpg", // Replace with your actual image URL
    date: "Michigan 2017",
  },  
  {
    subtitle: "Women's Entrepreneurship Trophy",
    imageUrl: "../images/awards/tropheeEntreprenariat.jpg", // Replace with your actual image URL
    date: "Paris 2013",
  },
  // Add more awards as needed
];

export default data;

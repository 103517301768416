import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";


function Rdm() {

  const images = [
    {
      original: './images/rdm/rdm1.jpg',
      thumbnail: './images/rdm/rdm1.jpg',
    },
    {
      original: './images/rdm/rdm2.jpg',
      thumbnail: './images/rdm/rdm2.jpg',
    },
    {
      original: './images/rdm/rdm3.jpg',
      thumbnail: './images/rdm/rdm3.jpg',
    },
  ];

 

  return <ImageGallery items={images} />;

  
}

export default Rdm;

import React from 'react';
import '../../App.css';
import Awards from '../Awards';
import Footer from '../Footer';

function Services() {
  return (
    <>
      <Awards/>
      <Footer/>
    </>
  );
}
export default Services

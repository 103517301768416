// About.js
import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';

function About() {
  return (
    <section className="about-page">
      <div className="biblio">
        <Link to="/" className="button close" aria-label="Close">
          &times;
        </Link>

        <div className="content-wrapper">
          <div className="imageBox">
            <img src={require('./images/couture/profile.png')} alt="Wafaa LAHLAH" />
          </div>
          <div className="content">
            <h1>Biography of Wafaa LAHLAH</h1>
            <p class="p1">
            Wafaa LAHLAH stands out with an unmistakable artistic signature. She captures everyone's attention; it's impossible not to notice her. Her passion for haute couture is palpable, and her boundless energy secures her a prominent place in this fascinating world. For this ambitious creator, fashion transcends mere passion; it is a true outlet, a privileged means of expression, and a vector of myriad desires where creativity flourishes endlessly. Her impressive journey attests to her undeniable status in the fashion industry. Wafaa LAHLAH is a captivating artist who enchants. The tone of her voice perfectly embodies the spirit of perseverance she has cultivated over the years.
          </p>

          <p class="p2">
            From a young age in Oran, Wafaa immersed herself in the secrets of sewing alongside her mother, a renowned couturier. She states, “I owe everything to her"; she is my greatest source of inspiration. After obtaining her BTS in fashion design and modeling from the (INFP), she founded the Ecole El Wafaa and her sewing workshop at just 24 years old, becoming the youngest woman to lead a fashion business in Algeria. At the same age, she gained recognition by teaching sewing on television in the Télématin segment.
            </p>
            <p class="p2">
            In 2005, Wafaa moved to Paris, where she honed her craft at the Chambre Syndicale de la Couture Parisienne, the International Academy of Cutting, and the Louvre Workshops. She worked as a first hand in the ateliers of Jean-Paul Benielli and completed an internship at the Christophe Josse house, an emblematic name in haute couture. Before broadening her skills, Wafaa participated in prestigious events, including:        
          <br />
          &#8208; International Artisan Fair, Nancy (2005)  <br />
          &#8208; Miss India France (2006) <br />
          &#8208; Fashion Show at the Bristol Hotel, Paris (2007) <br />
          &#8208; Fashion Night Couture, La Rochelle (2012) <br />
          &#8208;International Festival of African Fashion, Niamey (2013)<br />
          &#8208; France-Maghreb Convention, where she won an award (2013) <br />
          &#8208; Black & White Bridge the Gap Gala (2014) <br />
          &#8208; Miss United Nations Fashion Show, as an honorary guest (2017)<br />
          <br />
          </p>
        
            <p>
            Between 2014 and 2017, she expanded her skills as a sales advisor and personal shopper for prestigious brands such as Printemps, Galeries Lafayette, and Franck & Fils. In 2017, she was honored with the Oscar for Best Fashion Designer in Michigan and received the International Fashion Designer Award in Chicago. That same year, she was also named African Woman of the Year by a Parisian magazine dedicated to the African diaspora.
.
            </p>
            
            Wafaa has a deep appreciation for atypical materials: All my pieces are exclusively crafted from sought-after fabrics, defying the ordinary. I operate within a dynamic unique to me, and that is what sets me apart, Recognized for her bold use of unique materials, she favors unconventional textiles that redefine fashion norms, from shimmering feathers to exotic leathers. “I explore all materials and their infinite possibilities,” she states, reflecting her commitment to an ever-evolving art.


            <p>
            In 2019, she joined IncubAlliance, a well-known technology startup incubator, to refine her skills in managing innovative projects. 

            </p>
            


            <p> 
            Now based in Chicago, Wafaa is exploring the American luxury market, eager to blend her rich cultural heritage with contemporary trends. Although she is in a phase of discovery, her ambitions are grand, and she aspires to enrich intercultural dialogue through her creations.
          </p>

          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
import React from 'react';
import '../App.css';

import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
  
    
    </div>
  
  );
}

export default HeroSection;
